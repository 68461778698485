import axios from 'axios';
const PROTOCOL = "https://"
const URL = "rambl.in"


/**
 * Send Mail
 **/
export const addReview = (data) => {
    const req = axios.post(PROTOCOL + URL + ':8333/bullards-api/addReview/', data, {
        headers: {}
    }).then(res => {
        return res;
    }).catch(error => {
        throw error;
    })
    return req
}


/**
 * Get All Reviews
 **/
export const getAllReviews = () => {
    const req = axios.get(PROTOCOL + URL + ':8333/bullards-api/getReviews/', {
        headers: {}
    }).then(res => {
        return res;
    }).catch(error => {
        throw error;
    })
    return req
}