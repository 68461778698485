import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/styles.css';
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import { getAllSpecials } from '../api-calls/specials-api';

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}

class SpecialsPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			specials: [{}],
			isLoading: true
		}
		
	}
	componentDidMount = () => {
		getAllSpecials().then(res => {
			this.setState({ specials: res.data, isLoading: false });
			
		}).catch(error => {
			throw error;
		})
	}

	renderSpecials = () => {
		if (!this.state.isLoading) {
			if (this.state.specials !== undefined && this.state.specials !== null) {
				const listOfSpecials = this.state.specials;
				return (
					<span>
						{listOfSpecials.map((special) => (
						<span>
							<hr/>
							<h5>{special.name}</h5>
							<p>{special.description}</p>
						</span>
						))}
					</span>
				);
			}
		} else {
			//display loading icon here
		}
	}

    render() {
        return (
			<div>
				<Header></Header>
				<div id="bac-specials" class="bac-container">
					<div class="d-flex justify-content-center">
						<div>

							{/* <!-- CONTENT --> */}
							<div>
								<div class="px-4 px-lg-0">
									<h2 class="text-center py-4">Family Dinners</h2>
									<ul class="list-group list-group-flush">
										<div>
											<li class="list-group-item">
												{this.renderSpecials()}
											</li>
										</div>
									</ul>
								</div>
							</div>
							{/* <!-- CONTENT --> */}

							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}

						</div>
					</div>
				</div>
				<Footer></Footer>
			</div>
        );
    }
}

export default SpecialsPage;
