import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/styles.css';
import '../css/utilities.css';
import Header from '../components/header.js';
import Footer from '../components/footer.js';

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}
	
class MenuPage extends Component {
    render() {
        return (
			<div>
				<Header></Header>
				<div id="bac-menu" class="bac-container">
					<div class="d-flex justify-content-center">
						<div>

							{getSpacer()}

							{/* <!-- BREAKFAST --> */}
							<div>
								<div class="pt-4">
									<div class="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center">
										<div class="w-md-25">
											<img class="img-fluid" src="/img/food/bullards-food-019.jpg" alt="Breakfast Menu Description"/> 
										</div>
										<div class="w-md-75">
											<div class="pl-4">
												<h2>Breakfast</h2>
												<p>Whether you’re in the mood for a big breakfast or light bite we have what you need. Everything is fresh and homemade daily. Our creamed chipped beef is made from sautéed dried beef and whole milk. The sausage gravy has three different types of sausage ranging from sweet to mildly hot. Our eggs, French toast batter and pancake batter are whipped daily to give you the freshest meal possible. We also bake our corn muffins and cinnamon buns in house as well.</p>
												<a href="/pdf/breakfast-menu-005.pdf">
													<button class="btn btn-primary">View Menu</button>
												</a>
											</div>
										</div>
									</div>  
								</div>
							</div>
							{/* <!-- /BREAKFAST --> */}

							{getSpacer()}

							{/* <!-- LUNCH --> */}
							<div>
								<div class="py-4">
									<div class="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center">
										<div class="w-md-25">
											<img class="img-fluid" src="/img/food/bullards-food-021.jpg" alt="Lunch Menu Description"/>
										</div>
										<div class="w-md-75">
											<div class="pl-4">
												<h2>Lunch</h2> 
												<p>Once again, fresh and homemade are the key words here. Our chicken, tuna and egg salads are all made from scratch. The corned beef and pulled pork are slow cooked in house and along with the deli meats are sliced fresh to make the best possible sandwiches. The burgers are handmade, never frozen, and come with fresh cut fries. All entrée salads are made to order with fresh cut greens and vegetables that are cut daily. We feature a new scratch made soup every week so check back often.</p>
												<a href="/pdf/lunch-menu-005.pdf">
													<button class="btn btn-primary">View Menu</button>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- /LUNCH --> */}

							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}

						</div>    
					</div>
				</div>
				<Footer></Footer>
			</div>
        );
    }
}

export default MenuPage;
