import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/styles.css';
import { DateTime } from "luxon";
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import ReactStars from 'react-stars'
import { addReview,getAllReviews } from '../api-calls/reviews-api';
import Cookies from "universal-cookie";

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}

const cookies = new Cookies();

class TestimonialsPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userRating: 5,
			name: "",
			userReview: "",
			reviewError: false,
			hideForm: false,
			listOfTestimonials:[]
		}		
	}

	componentDidMount() {
		if (cookies.get("submitted") === "true") {
			const subName = cookies.get("subName");
			this.setState({ hideForm: true, name:subName });
		}

		getAllReviews().then(res => {
			this.setState({
				listOfTestimonials : res.data
			})
		}).catch(error => {
		
		})
	}

	addReview = (event) => {
		event.preventDefault();
		
		if ((this.state.name !== undefined && this.state.name !== null) &&
			(this.state.userReview !== undefined && this.state.userReview !== null)) {
	
			var object = {
				"name": "",
				"rating": "",
				"review":""
			};

			object["name"] = this.state.name;
			object["rating"] = this.state.userRating;
			object["review"] = this.state.userReview;

			this.setState({
				isLoading: true
			});

			addReview(object).then(res => {
				cookies.set("submitted", true, { path: "/" });
				cookies.set("subName", this.state.name, { path: "/" });
				this.setState({
					hideForm: true,
					isLoading: false,
					reviewError:false
				});


			}).catch(error => {
				this.setState({
					isLoading: false
				});
			})
		} else {
			this.setState({reviewError:true})
		}
	}

	ratingChanged = (newRating) => {
		//console.log(newRating);
		this.setState({userRating:newRating})
	}


	setReview = (review) => {
		
		this.setState({reviewError:false,userReview:review});
	}

	setName = (name) => {
		this.setState({reviewError:false,name:name});
	}

	renderTestimonials = () => {
		let testimonials = [];

		testimonials = this.state.listOfTestimonials
        console.log(testimonials)
		if (testimonials !== undefined && testimonials !== null) {
			
			return (
        <div>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <div className="testimonial-name">
                {testimonial.name}
                <span className="testimonial-score">
                  <ReactStars
                    count={5}
                    value={testimonial.rating}
                    size={14}
                    color2={"#ffd700"}
                    edit={false}
                  />
                  <span className="testimonial-date">
                    {DateTime.fromMillis(testimonial.submitDate).toRelative()}
                  </span>
                </span>
              </div>

              <div className="testimonial-review">
                <blockquote>{testimonial.review}</blockquote>
              </div>
              <hr />
            </div>
          ))}
        </div>
      );
		
		} else {
			return (
				<div>
					No Testimonials...
				</div>
			)
		}


	}

	showForm = () => {
		if (this.state.reviewError === true) {
			//toast error here
		}
		if (this.state.hideForm === false) {
			return (<form onSubmit={this.addReview}>
				<div>
					<h4>Leave A Review:</h4>
				</div>
				<div>
					<ReactStars count={5} onChange={this.ratingChanged} value={this.state.userRating} size={24} color2={'#ffd700'} />
				</div>
				<div class="form-group">
					{/* <label for="name">Name:</label> */}
					<input type="text" class="form-control" placeholder="Enter Name" id="name" name="name" onChange={e => this.setName(e.target.value)} />
				</div>
				<div className="form-group">
					{/* <label for="review">Review:</label> */}
					<textarea type="text" class="form-control" placeholder="Enter Review" id="review" name="review" rows="4" cols="50" onChange={e => this.setReview(e.target.value)} />
				</div>
				<div>
					<button type="submit" className="btn btn-primary">Add Review</button>
				</div>
			</form>)
		} else {
			return (<div>Thank You, {this.state.name} for your feedback!</div>)
		}
	}

    render() {
        return (
			<div>
				<Header></Header>
				<div id="bac-testimonials" class="bac-container">
					<div class="d-flex justify-content-center">
						<div>
							

							{/* <!-- CONTENT --> */}
							<div>
								<div class="px-4 px-lg-0 w-100">
									<h2 class="text-center py-4">Testimonials</h2>
									{/* <!-- DISPLAY TESTIMONIALS -- > */}
									<div>
										{this.renderTestimonials()}
									</div>
									{/* <!-- DISPLAY TESTIMONIALS -- > */}
									<div>
										{this.showForm()}
									</div>
								</div>
							</div>
							{/* <!-- CONTENT --> */}

							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}

						</div>
					</div>
				</div>
				<Footer></Footer>
			</div>
        );
    }
}

export default TestimonialsPage;
