import React, {Component, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/styles.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function getSpacer() {
	return  <div>&nbsp;</div>
}

class NoticeModal extends Component {
	state = { show: true };

	showModal = () => {
	  this.setState({ show: true });
	};
  
	hideModal = () => {
	  this.setState({ show: false });
	};

    render() {
        return (
			<div>
				
				{/* <!-- NOTICE --> */}
				<Modal show={this.state.show} onHide={this.hideModal}>
					<Modal.Header closeButton>
						{getSpacer()}
					</Modal.Header>
					<Modal.Body>
						<div className="d-flex flex-column">
							<div className="text-center">
								<img className="bac-logo" src="/img/logo/bullards-logo-007.png" alt="Bullards Cafe Logo"/>
							</div>
							<div className="text-center py-3">
							    Opening back up <em>Friday, 6/26, at 7 AM</em> for indoor seating! Please call <a class="bac-font-1" href="tel:215-441-9670">215-441-9670 </a> 
								 to make your reservation. You can still <a class="bac-font-1" href="tel:215-441-9670">call</a> in or order <a class="bac-font-1" href="https://www.toasttab.com/bullards-american-cafe-547-york-rd">online</a>. Thank you for supporting us during this difficult time. Be well and be safe.
							</div>  

							<div className="text-center">
							     In the meantime, take a look at our menus:<br/>
								<a class="bac-font-1" href="/pdf/breakfast-menu-005.pdf" class="bac-font-1">Breakfast</a> &#8226; <a class="bac-font-1" href="/pdf/lunch-menu-005.pdf">Lunch</a>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						{getSpacer()}
					</Modal.Footer>
				</Modal>
				{/* <!-- /NOTICE --> */}

			</div>
        );
    }
}

export default NoticeModal;
