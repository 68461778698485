import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/styles.css';
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import GoogleMapReact from "google-map-react";
import { sendMail } from "../api-calls/email-api"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

const buttonCSS = {
	cursor: 'pointer'
};

const AnyReactComponent = ({ text }) => <div>{text}</div>;
	
class ContactPage extends Component {
	constructor(props) {
		super(props);

		this.state={
			hideForm: false,
			isLoading: false
		}
	}

	static defaultProps = {
		center: {
			lat: 40.205420,
			lng: -75.100010
		},
		zoom: 15
	};

	openMapExternal = () => {
		if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPod") !== -1) || (navigator.platform.indexOf("iPad") !== -1)) {
			/* APPLE MAPS */
			window.open("maps://maps.apple.com/?daddr=Bullard's+American+Cafe");
		} else {
			/* GOOGLE MAPS */
			window.open("https://www.google.com/maps/dir/your+location/Bullard's+American+Cafe,+547+York+Rd,+Warminster,+PA+18974/");
		}
	}

	handleSubmit=(event)=> {
	  	event.preventDefault();
		const data = new FormData(event.target);
		
		var object = {};
		data.forEach((value, key) => {
			// Reflect.has in favor of: object.hasOwnProperty(key)
			if (!Reflect.has(object, key)) {
				object[key] = value;
				return;
			}
			if (!Array.isArray(object[key])) {
				object[key] = [object[key]];
			}
			object[key].push(value);
		});

		console.log(object);

		this.setState({ isLoading: true });
		
		sendMail(object).then(res => {
			this.setState({
				hideForm: true,
				isLoading: false
			});

		}).catch(error => {
			this.setState({
				isLoading: false
			});
		})
	
	  	
	}

	showForm = () => {
		if (this.state.isLoading === true) {
			return (
				<div>
					Loading...
				</div>
			)
		}
		else if (this.state.hideForm === false) {
			return (
				<form onSubmit={this.handleSubmit} id="bac-contact-form">
					<div>
						<div>
							<div className="bac-form-error-msg" id="successfail">
								{/* {{errorMsg}} */}
							</div>
						</div>
					</div>
					<div>
						<div id="message">
							<div className="form-group">
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"><FontAwesomeIcon icon={faUser}/></span>
									</div>
									<input className="form-control" type="text" id="from-name" name="name" required placeholder="Full Name" />
								</div>
							</div>
							<div className="form-group">
								<div className="input-group">
									<div className="input-group-prepend">
										<span className="input-group-text"><FontAwesomeIcon icon={faEnvelope}/></span>
									</div>
									<input className="form-control" type="text" id="from-email" name="email" required placeholder="Email Address" />
								</div>
							</div>
							<div className="form-group">
								<textarea className="form-control" id="from-message" name="message" placeholder="Your Message" rows="5"></textarea>
							</div>
							<div className="form-group">
								<button className="btn btn-primary btn-block" type="submit">Submit <FontAwesomeIcon icon={faChevronCircleRight} size="sm"/></button>
							</div>
						</div>
					</div>
				</form>);
		} else {
			return (
				<div className="text-center">
					<br />
					<h4>Thank You For Contacting Bullard's Cafe!</h4>
					<br />
					<br />
					<h5>We will get back to you as soon as possible.</h5>
					<br />
					<br />
					<br />
				</div>
			);
		}
	}

	render() {
		return (
			<div>
				<Header></Header>
				<div id="bac-contact" className="bac-container">
					<div className="d-flex justify-content-center">
						<div className="w-100">

							{/* <!-- MAP --> */}
							<div>
								<div className="pt-4">
									<h2>Find Us</h2>
									<div id="divv33-map">									
										<GoogleMapReact
											bootstrapURLKeys={{key: "AIzaSyBYjCrtjEV4v5UK2e3Pg8H-DusreWct2MQ"}}
											defaultCenter={this.props.center}
											defaultZoom={15}>
												<AnyReactComponent
													lat={40.205420}
													lng={-75.100010}
													text="Bullard's American Cafe"
												/>
										</GoogleMapReact>
									</div>
								</div>									
								<div className="px-4 px-md-0">
									<div><FontAwesomeIcon icon={faMapMarkerAlt} size="xs"/>&nbsp;&nbsp;547 York Road, Warminster, PA 18974</div>
									<div><FontAwesomeIcon icon={faPhone} size="xs"/>&nbsp;(215) 441-9670</div>
									<div className="pt-1"><button className="btn btn-primary" onClick={ ()=>this.openMapExternal()} style={buttonCSS}>Get Directions</button></div>
								</div>
							</div>
							{/* <!-- /MAP --> */}

							{/* <!-- HOURS --> */}
							<div>
								<div className="pt-4 px-4 px-md-0">
									<h2>Hours</h2>
									<div>Open Daily 8:00AM - 2:00PM</div>
								</div>
							</div>
							{/* <!-- /HOURS --> */}

							{/* <!-- FORM --> */}
							<div>
								<div className="py-4 px-4 px-md-0">
									<h2>Contact Us</h2>			
									<div>			
										{this.showForm()}
									</div>										
								</div>
							</div>
							{/* <!-- /FORM --> */}

						</div>
					</div>
				</div>
				<Footer></Footer>
			</div> 
		);
	}
}

export default ContactPage;
