import axios from 'axios';
const PROTOCOL = "https://"
const URL = "rambl.in"


/**
 * Get All Specials
 **/
export const getAllSpecials = (data) => {
    const req = axios.get(PROTOCOL + URL + ':8333/bullards-api/getAllSpecials/', {
        headers: {}
    }).then(res => {
        return res;
    }).catch(error => {
        throw error;
    })
    return req
}
