import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/styles.css';
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import NoticeModal from '../components/notice-modal.js';
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from "mdbreact";

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}

class HomePage extends Component {
    render() {
        return (
			<div>
				<Header></Header>
				<div id="bac-home" class="bac-container">
					<div class="d-flex justify-content-center">
						<div>

							{/* <!-- SLIDER --> */}
							<div>
								<MDBContainer className="p-0 m-0">
									<MDBCarousel activeItem={1} length={8} showControls={true} showIndicators={true} className="z-depth-1" slide>
										<MDBCarouselInner>
											<MDBCarouselItem itemId="1">
												<MDBView>
													<img className="d-block w-100" src="/img/carousel/slide-001.jpg" alt="Slide 001"/>
												</MDBView>
											</MDBCarouselItem>
											<MDBCarouselItem itemId="2">
												<MDBView>
													<img className="d-block w-100" src="/img/carousel/slide-002.jpg" alt="Slide 002"/>
												</MDBView>
											</MDBCarouselItem>
											<MDBCarouselItem itemId="3">
												<MDBView>
													<img className="d-block w-100" src="/img/carousel/slide-003.jpg" alt="Slide 003"/>
												</MDBView>
											</MDBCarouselItem>
											<MDBCarouselItem itemId="4">
												<MDBView>
													<img className="d-block w-100" src="/img/carousel/slide-004.jpg" alt="Slide 004"/>
												</MDBView>
											</MDBCarouselItem>
											<MDBCarouselItem itemId="5">
												<MDBView>
													<img className="d-block w-100" src="/img/carousel/slide-005.jpg" alt="Slide 005"/>
												</MDBView>
											</MDBCarouselItem>
											<MDBCarouselItem itemId="6">
												<MDBView>
													<img className="d-block w-100" src="/img/carousel/slide-006.jpg" alt="Slide 006"/>
												</MDBView>
											</MDBCarouselItem>
											<MDBCarouselItem itemId="7">
												<MDBView>
													<img className="d-block w-100" src="/img/carousel/slide-007.jpg" alt="Slide 007"/>
												</MDBView>
											</MDBCarouselItem>
											<MDBCarouselItem itemId="8">
												<MDBView>
													<img className="d-block w-100" src="/img/carousel/slide-008.jpg" alt="Slide 008"/>
												</MDBView>
											</MDBCarouselItem>
										</MDBCarouselInner>
									</MDBCarousel>
								</MDBContainer>
							</div>
							{/* <!-- /SLIDER --> */}

							{/* <!-- CONTENT --> */}
							<div>
								<div class="px-4 px-lg-0">
									<h2 class="pt-4"></h2>
									<p>Bullard’s American Café has been serving Warminster and the surrounding towns since 2015. Our small but mighty café is nestled in a little strip mall near the intersection of York and Street Roads. Once you find us we are confident you will be back again and again.</p>
									<p>We have an extensive menu of breakfast and lunch offerings that are available all day, every day. Every plate is made to order from fresh ingredients from our kitchen to your table. Our wide array of choices includes many gluten free options as well as vegetarian and vegan if you so choose. We have an intimate dining space as well as an open kitchen which allows you almost direct access to the chef through your server.</p>
									<p>In addition to our regular menu we run specials that begin every Friday and run to the following Thursday. Please check back regularly to see what is being featured. Thank you for visiting our page and we look forward to serving you soon.</p>
								</div>
							</div>
							{/* <!-- CONTENT --> */}

							{getSpacer()}
							{getSpacer()}
							{getSpacer()}
							{getSpacer()}

						</div>
					</div>
				</div> 
				<Footer></Footer>
				{/* <NoticeModal></NoticeModal> */}
			</div>
        );
    }
}

export default HomePage;
