import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Redirect, Route, Link } from 'react-router-dom';
import HomePage from './pages/home.js';
import MenuPage from './pages/menu.js';
import SpecialsPage from './pages/specials.js';
import TestimonialsPage from './pages/testimonials.js';
import GalleryPage from './pages/gallery.js';
import AboutPage from './pages/about.js';
import ContactPage from './pages/contact.js';

ReactDOM.render(
     <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/menu" component={MenuPage} />
          <Route path="/specials" component={SpecialsPage} />
          <Route path="/testimonials" component={TestimonialsPage} />
          <Route path="/gallery" component={GalleryPage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/contact" component={ContactPage} />
          <Redirect to="/" />
       </Switch>
     </BrowserRouter>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
