import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const tempCSS = {
	height: '150px',
	width: '150px'
};
	
class Header extends Component {
	constructor() {
		super()
		this.state={
			visible:false
		}
	}
	toggleState() {
		this.setState({
			visible:!this.state.visible
		})
	}
    render() {
        return (
			<div>

				 {/* <!-- HEADER --> */}
				<div id="bac-header">
					<div class="d-flex flex-column">
						<div>
							<div class="d-flex justify-content-between">
								<div>
									<div class="d-md-none d-flex h-100">
										<div class="justify-content-center align-self-center p-2 mx-3">
											<a href="tel:215-441-9670" aria-label="Phone Number">
												<FontAwesomeIcon icon={faPhone} size="lg" transform="rotate-90" className="bac-txt-blue"/>
											</a>
										</div>
									</div>
								</div>
								<div>
									<div class="p-2">
										<a href="#/home" aria-label="Home Page">
											<img class="bac-logo" src="/img/logo/bullards-logo-007.png" alt="Bullards Cafe Logo"/>
										</a>
									</div>
								</div>
								<div>
									<div class="d-md-none d-flex h-100">
										<div class="justify-content-center align-self-center p-2">
											<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={()=>this.toggleState()}>
												<FontAwesomeIcon icon={faBars} size="lg"/>
											</button>
										</div>
									</div>
								</div>                    
							</div>
						</div>

						{/* <!-- DESKTOP NAVIGATION--> */}
						<div id="navigation-d">
							<div class="d-none d-md-block">
								<div id="bac-banner" class="d-flex justify-content-center">  
									<div class="p-1">
										<Link to="/home" className="nav-link m-0 p-0">
											<div class="bac-font-1">Home</div>
										</Link>
									</div>
									<div class="p-1 star-size bac-font-2">
										<FontAwesomeIcon icon={faStar}/>
									</div>									
									<div class="p-1">
										<Link to="/menu" className="nav-link m-0 p-0">
											<div class="bac-font-1">Menus</div>
										</Link>
									</div>
									<div class="p-1 star-size bac-font-2">
										<FontAwesomeIcon icon={faStar}/>
									</div>
									<div class="p-1">
										<a href="https://www.toasttab.com/bullards-american-cafe-547-york-rd" className="nav-link m-0 p-0" target="_blank">
											<div class="bac-font-1">Order Online</div>
										</a>
									</div>
									<div class="p-1 star-size bac-font-2">
										<FontAwesomeIcon icon={faStar}/>
									</div>
									<div class="p-1">
										<a href="https://www.toasttab.com/bullards-american-cafe-547-york-rd/giftcards" className="nav-link m-0 p-0" target="_blank">
											<div class="bac-font-1">Gift Cards</div>
										</a>
									</div>
									<div class="p-1 star-size bac-font-2">
										<FontAwesomeIcon icon={faStar}/>
									</div>
									<div class="p-1">
										<Link to="/specials" className="nav-link m-0 p-0">
											<div class="bac-font-1">Family Dinners</div>
										</Link>
									</div>
									<div class="p-1 star-size bac-font-2">
										<FontAwesomeIcon icon={faStar}/>
									</div>
									<div class="p-1">
										<Link to="/testimonials" className="nav-link m-0 p-0">
											<div class="bac-font-1">Testimonials</div>
										</Link>
									</div>
									<div class="p-1 star-size bac-font-2">
										<FontAwesomeIcon icon={faStar}/>
									</div>
									<div class="p-1">
										<Link to="/gallery" className="nav-link m-0 p-0">
											<div class="bac-font-1">Gallery</div>
										</Link>
									</div>
									<div class="p-1 star-size bac-font-2">
										<FontAwesomeIcon icon={faStar}/>
									</div>
									<div class="p-1">
										<Link to="/about" className="nav-link m-0 p-0">
											<div class="bac-font-1">About</div>
										</Link>
									</div>
									<div class="p-1 star-size bac-font-2">
										<FontAwesomeIcon icon={faStar}/>
									</div>
									<div class="p-1">
										<Link to="/contact" className="nav-link m-0 p-0">
											<div class="bac-font-1">Contact</div>
										</Link>
									</div>
								</div>       
							</div>
						</div>
						{/* <!-- /DESKTOP NAVIGATION--> */}
						
						{/* <!-- MOBILE NAVIGATION--> */}
						<div id="navigation-m" class="d-md-none">
							{this.state.visible?
								<div class="d-flex align-content-start flex-wrap">								
									<div class="w-50 pl-2 pr-1 pt-2">
										<Link to="/home" className="nav-link m-0 p-0">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">Home</div>												
											</div>  
										</Link> 
									</div>
									<div class="w-50 pl-1 pr-2 pt-2">
										<Link to="/menu" className="nav-link m-0 p-0">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">Menus</div>												
											</div>
										</Link>
									</div>
									<div class="w-50 pl-2 pr-1 pt-2">
										<a href="https://www.toasttab.com/bullards-american-cafe-547-york-rd" className="nav-link m-0 p-0" target="_blank">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">Order Online</div>												
											</div>  
										</a> 
									</div>
									<div class="w-50 pl-1 pr-2 pt-2">
										<a href="https://www.toasttab.com/bullards-american-cafe-547-york-rd/giftcards" className="nav-link m-0 p-0" target="_blank">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">Gift Cards</div>												
											</div>
										</a>
									</div>
									<div class="w-50 pl-2 pr-1 pt-2">
										<Link to="/specials" className="nav-link m-0 p-0">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">Family Dinners</div>												
											</div>
										</Link>
									</div>
									<div class="w-50 pl-1 pr-2 pt-2">
										<Link to="/testimonials" className="nav-link m-0 p-0">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">Testimonials</div>
											</div>									
										</Link>
									</div>
									<div class="w-50 pl-2 pr-1 pt-2">
										<Link to="/gallery" className="nav-link m-0 p-0">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">Gallery</div>												
											</div>
										</Link>
									</div>
									<div class="w-50 pl-1 pr-2 pt-2">
										<Link to="/about" className="nav-link m-0 p-0">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">About</div>												
											</div>
										</Link>
									</div>
									<div class="w-100 pl-2 pr-2 py-2">
										<Link to="/contact" className="nav-link m-0 p-0">
											<div class="nav-mobile-btn d-flex justify-content-center align-items-center">												
												<div class="bac-font-7">Contact</div>												
											</div>
										</Link>
									</div>								
								</div>
							:null}
						</div>
						{/* <!-- /MOBILE NAVIGATION--> */}

					</div>
				</div>
				{/* <!-- /HEADER --> */}

			</div>
        );
    }
}

export default Header;
