import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/styles.css';
import Header from '../components/header.js';
import Footer from '../components/footer.js';
	
const imageCSS = {
	objectFit: 'cover'
};

class AboutPage extends Component {
    render() {
        return (
			<div>
				<Header></Header>
				<div id="bac-about" class="bac-container">
					<div class="d-flex justify-content-center">
						<div>

							{/* <!-- OWNERS --> */}
							<div>
								<div class="px-4 px-lg-0">
									<h2 class="text-center pt-4">Owners</h2>
									<img src="/img/staff/bullards-staff-003.jpg" class="float-left pr-4 pd-4" alt="Owners Description" width="300" height="300" style={imageCSS}/>
									<p>Christine and I met in 1987 in the restaurant business and were married in 1988. We are the proud parents of 3 daughters and even prouder grandparents of 2 grandsons and 2 granddaughters.  We still reside in the house where we raised our children, along with our 3 rescue dogs (a/k/a replacement kids)</p>
									<p>We both left the restaurant business in the early 90’s but our love of food and cooking never stopped.  Whether it was family functions, tailgate parties, catering a friend’s special event or our daughter’s wedding, it seems we were always in the kitchen.</p>
									<p>I met Gary and Joyce in February of 2018 when I answered a Craigslist ad for a cook.  We hit it off immediately and I began doing dinners with him on Thursday and Friday.  About a month in, after a really bad week personally, I went and told Gary I had to quit.  I went home and told Christine what I had done and she said “you better go back and unquit”.  Gary had gone home and told Joyce I quit, and she told him you better get him to unquit!  I went back the next day and spoke with him and told him I’d like to have the job back.  I was proud when he told me you’re the first person I’ve ever let “unquit”.</p>
									<p>I learned a lot the year I got to spend with Gary and Joyce.  I saw their dedication and hard work pay off in the form of loyal customers and better sales.  When the opportunity came to Christine and me to purchase the café after Gary’s sudden passing we knew it was the right thing for us.</p>
									<p>What you see and eat at Bullard’s is who we are.  When you look around and see fresh flowers, and a décor that fits every season or holiday, you can be sure that Christine was there after hours making the place warm and inviting.  When your food arrives fresh and just as ordered you can be sure that no plate leaves that kitchen that I wouldn’t be proud of.  We are honored to be continuing the legacy that Joyce and Gary created. In many ways, it feels like we’ve been practicing our entire lives for this moment.  Our goal is that you feel like a guest in our home while at our restaurant.</p>  
								</div>
							</div>
							{/* <!-- /OWNERS --> */}

							{/* <!-- FOUNDERS --> */}
							<div>
								<div class="px-4 px-lg-0">
									<h2 class="text-center pt-4">Founders</h2>
									<p>Gary grew up in Swarthmore, PA along with his 2 brothers and parents. After high school he went on to graduate from the Culinary Institute of America in Hyde Park, NY in 1976. Gary had an extensive professional career. He held positions at the Omni Hotel, San Francisco, CA, Goodies from Goodman, Dallas, TX, and was the Chef at The Buck Hotel, Stonehouse Bistro and Ann's Choice, Bucks County, PA. He also was the Chef for Gate Gourmet at the Philadelphia Airport where he did menu development for the Acela Express Railroad.</p>
									<p>I grew up in Warminster along with my 10 siblings and parents. After graduating high school, I went on to receive my Bachelor's Degree in Science at Rider College in 1981. A few years later I began my Real Estate Career.</p>
									<p>Gary &amp; I met in 1987 working together at the Spread Eagle Inn, Richboro. We married in 1990 and welcomed our Son, Steven in 1991. While Gary worked in the restaurant business, I continued to build my Real Estate career.</p>
									<img src="/img/staff/bullards-staff-001.jpg" class="float-right pl-4 pb-4" alt="Founders Description" width="300" height="300" style={imageCSS}/>
									<p>It was Gary's dream to open a small, homey restaurant focusing on breakfast &amp; lunch. In 2015 when we realized the opportunity was available in Warminster
									(my home town) we were thrilled. On March 5, 2015 we opened Meet me at...Bullard's American Cafe. Gary brought his culinary knowledge, talent and unique cooking style to the Cafe. The open kitchen enabled him to converse with the customers and his keen sense of humor kept the laughter flowing. Weekends I helped out so he could focus on the food.</p>
									<p>With hard work and dedication we built a thriving business together in just 4 short years.  We were extremely thankful to our family, friends, support staff and all the wonderful people we met along the way. We were most grateful that customers became friends.</p>                
									<p>I am thankful for all Michael &amp; Christine have done and continue to do to keep Gary's dream alive!! He would have been thrilled, as I am. Please carry his dream forward with our blessing to much success knowing that he is with you!</p>
									<p><em>Joyce Rankin-Bullard</em></p>
								</div>
							</div>
							{/* <!-- /FOUNDERS --> */}

						</div>
					</div>
				</div> 
				<Footer></Footer>
			</div>
        );
    }
}

export default AboutPage;
