import axios from 'axios';
const PROTOCOL = "https://"
const URL = "rambl.in"


/**
 * Send Mail
 **/
export const sendMail = (data) => {
    const req = axios.post(PROTOCOL + URL + ':8333/bullards-api/sendEmail/',data, {
        headers: {}
    }).then(res => {
        return res;
    }).catch(error => {
        throw error;
    })
    return req
}