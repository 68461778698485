import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './css/colors.css';

function App() {
	return (
		<div className="App">
			{/* Do Nothing */}
		</div>
	);
}

export default App;
