import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/styles.css';
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const photos = [
	{
		"src": "img/food/bullards-food-001.jpg"
	}, {
		"src": "img/food/bullards-food-002.jpg"
	}, {
		"src": "img/food/bullards-food-008.jpg"
	}, {
		"src": "img/food/bullards-food-011.jpg"
	}, {
		"src": "img/food/bullards-food-012.jpg"
	}, {
		"src": "img/food/bullards-food-014.jpg"
	}, {
		"src": "img/food/bullards-food-017.jpg"
	}, {
		"src": "img/food/bullards-food-018.jpg"
	}, {
		"src": "img/food/bullards-food-019.jpg"
	}, {
		"src": "img/food/bullards-food-020.jpg"
	}, {
		"src": "img/food/bullards-food-021.jpg"
	}, {
		"src": "img/food/bullards-food-022.jpg"
	}, {
		"src": "img/food/bullards-food-023.jpg"
	}, {
		"src": "img/restaurant/bullards-restaurant-009.jpg"
	}, {
		"src": "img/staff/bullards-staff-002.jpg"
	}
];
	
class GalleryPage extends Component {
    constructor(props) {
    	super(props);

    	this.state = {
    		photoIndex: 0,
    		isOpen: false,
    	};
	}

	/**
	 * Renders the Gallery
	 */
	renderGallery = () => {
		if (photos !== undefined && photos !== null) {
			return (
				<React.Fragment>
					{photos.map((photo, photoIndex) => (
						<div onClick={() => this.setState({ isOpen: true, photoIndex:photoIndex })}>
							<div class="picture-boxes m-4"
								style={{
									cursor: 'pointer',
									backgroundImage: 'url(' + photo['src'] + ')',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									height: '300px',
									width: '300px'
								}}>				
							</div>
						</div>)
					)}
				</React.Fragment>
			);			
		}
	}

    render() {
        return (
			<div>
				<Header></Header>	
				<div id="bac-gallery" className="bac-container">
					<div className="d-flex justify-content-center">
						<div>

							{/* <!-- TITLE --> */}
							<div>                    
								<h2 className="text-center pt-4">Cafe Gallery</h2>         
							</div>
							{/* <!-- /TITLE --> */}

							{/* <!-- GALLERY --> */}
							<div>
								<div className="d-flex justify-content-center align-items-center flex-wrap">
									{this.renderGallery()}
								</div>
								{
									this.state.isOpen && ( 
										<Lightbox 
											mainSrc = {
												photos[this.state.photoIndex]['src']
											}
											nextSrc = {
												photos[(this.state.photoIndex + 1) % photos.length]['src']
											}
											prevSrc = {
												photos[(this.state.photoIndex + photos.length - 1) % photos.length]['src']
											}
											onCloseRequest = { () => 
												this.setState({
													isOpen: false
												})
											}
											onMovePrevRequest = { () => 
												this.setState({
													photoIndex: (this.state.photoIndex + photos.length - 1) % photos.length,
												})
											}
											onMoveNextRequest = { () => 
												this.setState({
													photoIndex: (this.state.photoIndex + 1) % photos.length,
												})
											} >
										</Lightbox>
									)
								}
							</div>
							{/* <!-- /GALLERY --> */}

						</div>
					</div>
				</div> 

				<Footer></Footer>
			</div>
        );
    }
}

export default GalleryPage;
