import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYelp } from "@fortawesome/free-brands-svg-icons";

const footerCSS = {
	minHeight: '100px'
};

function getYear() {
    return new Date().getFullYear();
}
	
class Footer extends Component {
    render() {
        return (
			<div>
				
				{/* <!-- FOOTER --> */}
				<div id="bac-footer" class="p-5">
					<div class="d-flex flex-column flex-md-row">
						<div class="w-100 flex-fill">
							<div class="d-flex flex-column">
								<div>
									<a href="#/home" aria-label="Home Page"><img class="bac-logo" src="/img/logo/bullards-logo-007.png" alt="Bullards Cafe Logo"/></a>
								</div>
								<div id="bac-footer-links" class="py-2">
									<Link to="/home">Home</Link>
									<span> · </span>
									<Link to="/menu">Menus</Link>
									<span> · </span>
									<a href="https://www.toasttab.com/bullards-american-cafe-547-york-rd" target="_blank">Order Online</a>
									<span> · </span>
									<a href="https://www.toasttab.com/bullards-american-cafe-547-york-rd/giftcards" target="_blank">Gift Card</a>
									<span> · </span>
									<Link to="/specials">Family Dinners</Link>
									<span> · </span>
									<Link to="/testimonials">Testimonials</Link>
									<span> · </span>
									<Link to="/gallery">Gallery</Link>
									<span> · </span>
									<Link to="/about">About</Link>
									<span> · </span>
									<Link to="/contact">Contact</Link>
								</div>
								<div>
									<div class="bac-font-5">Bullard's American Cafe © {getYear()}</div>
								</div>
							</div>
						</div>
						<div class="w-100 flex-fill">
							<div class="d-flex flex-column py-5 py-md-0">
								<div class="d-flex h-100">
									<div class="footer-contacts-icon">
										<FontAwesomeIcon icon={faMapMarkerAlt} size="md"/>
									</div>
									<div class="justify-content-center align-self-center">
										<a class="bac-font-6" href="#/contact" aria-label="Contact Page">547 York Rd, Warminster, PA 18974</a>
									</div>
								</div>
								<div class="d-flex h-100">
									<div class="footer-contacts-icon">
										<FontAwesomeIcon icon={faPhone} size="md"/>
									</div>
									<div class="justify-content-center align-self-center">
										<a class="bac-font-6" href="tel:215-441-9670" aria-label="Phone Number">(215) 441-9670</a>
									</div>
								</div>
								<div class="d-flex h-100">
									<div class="footer-contacts-icon">
										<FontAwesomeIcon icon={faEnvelope} size="md"/>
									</div>
									<div class="justify-content-center align-self-center">
										<a class="bac-font-6" href="#/contact" aria-label="Contact Page">bullardsamericancafe@gmail.com</a>
									</div>
								</div>
							</div>
						</div>
						<div class="w-100 flex-fill">
							<div class="d-flex flex-column">
								{/* <!-- <div>
									<h4 class="bac-font-3">WHY WE'RE DIFFERENT ...</h4>
								</div> --> */}
								<div>
									<p class="bac-font-4">At Bullard's, our customers are like family. We continually strive to provide a home-like atmosphere and a friendly, comfortable environment. Meet our chef Mike, our customer-friendly wait staff, and our regular customers</p>
								</div>
								<div class="social-links social-icons">
									<a href="https://www.facebook.com/Bullards-American-Cafe-347928972062334/" aria-label="Facebook" target="_blank" rel="noopener">
										<FontAwesomeIcon icon={faFacebookF} size="md"/>
									</a>
									<a href="https://www.instagram.com/bullardsamericancafe/" aria-label="Instagram" target="_blank" rel="noopener">
										<FontAwesomeIcon icon={faInstagram} size="md"/>
									</a>
									<a href="https://www.yelp.com/biz/bullards-american-cafe-warminster" aria-label="Yelp" target="_blank" rel="noopener">
										<FontAwesomeIcon icon={faYelp} size="md"/>
									</a>
									<a href="https://divvision33.com/#/" aria-label="Divvision33" target="_blank" rel="noopener">
										33
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- /FOOTER --> */}
				
			</div>
        );
    }
}

export default Footer;
